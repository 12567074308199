import * as React from "react";
const SvgDermaIcon2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 168 168"
    {...props}
  >
    <g clipPath="url(#derma-icon-2_svg__a)">
      <path
        fill="#fff"
        stroke="#828995"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M84.011 167.168c45.927 0 83.157-37.23 83.157-83.157S129.938.854 84.011.854.854 38.084.854 84.011s37.23 83.157 83.157 83.157"
      />
      <path fill="#E77C6A" d="M123.005 52.973H52.209v70.796h70.796z" />
      <path
        stroke="#828995"
        strokeMiterlimit={10}
        d="M64.165 45.512v7.461M79.314 45.512v7.461M94.844 45.512v7.461M110.756 45.512l-.202 7.461"
      />
      <path
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={0.75}
        d="M66.975 73.47h7.327M83.944 73.47h7.327M100.215 73.47h7.305M100.215 73.47h7.305M66.975 85.652h7.327M83.944 85.652h7.327M100.215 85.652h7.305M67.177 97.81h7.305M84.146 97.81h7.327M67.177 109.97h7.305M84.146 109.97h7.327M100.215 97.81h7.305M100.215 109.97h7.305"
      />
      <path
        stroke="#828995"
        strokeMiterlimit={10}
        strokeWidth={0.76}
        d="M107.52 131.231H44.995V65.694"
      />
    </g>
    <defs>
      <clipPath id="derma-icon-2_svg__a">
        <path fill="#fff" d="M0 0h168v168H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDermaIcon2;
