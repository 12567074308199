import React from "react";
import styled from "styled-components";
import { Markup } from "interweave";
import { CrohnsDisease, Psoriasis, Psoriatic } from "../../../assets/svgs";

const SectionHeader = ({ title, text, pathname, icons }) => {
  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      {icons ? (
        <Grid>
          <Text inside>
            <Markup content={text} />
          </Text>
          {pathname.includes("gastroenterolog") ? (
              <Icon>
                <CrohnsDisease />
                <span>Crohn's Disease</span>
              </Icon>
            ) : (
              <Icons>
                <Icon>
                  <Psoriasis />
                  <span>Plaque Psoriasis<br />and Paediatric<br />Plaque Psoriasis</span>
                </Icon>
                <Icon>
                  <Psoriatic />
                  <span>Psoriatic<br />Arthritis</span>
                </Icon>
              </Icons>
            )}
        </Grid>
      ) : (
        <Text>
          <Markup content={text} />
        </Text>
      )}
    </Container>
  );
};

const Container = styled.section`
  background: linear-gradient(90deg, #412a67 0%, #e77c6a 100%);
  color: #ffffff;
  display: grid;
  grid-column: 1/7;
  grid-template-columns: repeat(6, 1fr);
  @media screen and (min-width: 1024px) {
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Title = styled.div`
  align-items: center;
  background: var(--uzpruvo-orange);
  border-bottom-right-radius: 40px 40px;
  border-top-right-radius: 40px 40px;
  border-bottom: 1px solid #ffffff;
  border-top: 1px solid #ffffff;
  color: #ffffff;
  display: flex;
  font-size: 1.625rem;
  font-weight: 300;
  height: 100%;
  justify-content: center;
  padding: 1.5rem 3rem 1.5rem 1.25rem;
  position: absolute;
  top: -50%;
  left: 0;
  &:before {
    border-bottom: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
    content: "";
    height: calc(100% + 2px);
    background: var(--uzpruvo-orange);
    position: absolute;
    width: 500%;
    right: 100%;
    top: -1px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 2.375rem;
    padding: 0 3rem 0 0;
  }
`;

const TitleContainer = styled.div`
  grid-column: 1/7;
  position: relative;
  height: 50px;
  @media screen and (min-width: 1024px) {
    grid-column: 2/12;
    height: 80px;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  padding: 0 1.25rem;
  margin-bottom: 2rem;
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-column: 2/12;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

const Text = styled.div`
  grid-column: ${({ inside }) => inside ? '1' : '1/7'};
  padding: 0 1.25rem;
  margin: 1rem 0 3rem 0;
  > span {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
  }
  h3 {
    font-weight: 300;
    font-size: 1.125rem;
    text-transform: uppercase;
    strong {
      font-weight: 500;
    }
  }
  h4 {
    font-weight: 300;
    font-size: 1.5rem;
    span {
      display: block;
      font-size: 1rem;
      font-weight: 500;
    }
  }
  h5 {
    font-size: 0.75rem;
    font-weight: 300;
  }
  ul {
    font-size: 1rem;
    font-weight: 500;
    margin-left: 1.875rem;
    margin-top: -1rem;
    li {
      margin-bottom: 1rem;
    }
  }
  p {
    font-size: 0.625rem;
  }
  @media screen and (min-width: 740px) {
    gap: 1.5rem;
    h3 {
      font-size: 1.625rem;
    }
    h4 {
      font-size: 2rem;
      span {
        font-size: 1.625rem;
      }
      strong {
        font-weight: 500;
      }
    }
    h5 {
      font-size: 1.25rem;
    }
    ul {
      margin-top: -3rem;
      font-size: 1.625rem;
    }
    p {
      font-size: 1.25rem;
    }
  }
  @media screen and (min-width: 1024px) {
    grid-column: ${({ inside }) => inside ? '1' : '2/12'};
    padding: 0;
    margin: 2rem 0 6rem 0;
    > span {
      gap: 3rem;
    }
  }
  @media screen and (min-width: 1600px) {
    grid-column:${({ inside }) => inside ? '1' : '3/11'};
  }
`;

const Icons = styled.div`
align-items: flex-start;
  display: flex;
  justify-content: center;
  gap: 2rem;
  @media screen and (min-width: 740px) {
    gap: 3rem;
  }
`;

const Icon = styled.div`
  display: grid;
  font-size: 0.75rem;
  font-weight: 500;
  gap: 1.5rem;
  grid-template-rows: 80px auto;
  text-align: center;
  text-transform: uppercase;
  svg {
    height: 100%;
    width: 100%;
  }
  @media screen and (min-width: 740px) {
    grid-template-rows: 174px auto;
    font-size: 1.25rem;
  }
`;

export default SectionHeader;
