import * as React from "react";
const SvgDermaIcon4 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 168 169"
    {...props}
  >
    <g clipPath="url(#derma-icon-4_svg__a)">
      <path
        fill="#fff"
        stroke="#828995"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M84.011 167.893c45.927 0 83.157-37.23 83.157-83.157S129.938 1.58 84.011 1.58.854 38.809.854 84.736s37.23 83.157 83.157 83.157"
      />
      <path
        stroke="#828995"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M83.674 107.571c1.393 4.202 4.652 12.81 6.09 16.991 1.507 4.382 3.08 8.742 4.608 13.125.157.472.36.944.607 1.641.292-.562.45-.877.607-1.192 1.708-3.663 3.416-7.304 5.124-10.967.652-1.394 1.393-1.619 2.764-.944 1.259.629 2.495 1.258 3.731 1.888 2.675 1.348 5.349 2.697 8.024 4.023.067-.068.135-.113.202-.18-4.135-11.934-10.72-27.082-14.946-39.309m-33.15.315c-4.428 9.26-11.642 22.228-16.16 31.667.742-.247 1.124-.405 1.529-.539 3.663-1.349 7.304-2.697 10.967-4.023 1.619-.585 2.113-.382 2.697 1.213 1.169 3.259 2.27 6.54 3.394 9.799.315.899.607 1.798 1.011 3.034 5.394-11.26 10.699-23.059 16.07-34.319"
      />
      <path
        fill="#E77C6A"
        d="M66.503 61.857c-2.81 10.023 2.967 20.474 12.9 23.306 10.092 2.877 20.588-2.922 23.487-12.968 2.899-10.069-3.034-20.564-13.193-23.419-9.979-2.787-20.385 3.08-23.171 13.103z"
      />
      <path
        stroke="#828995"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="m61.289 47.563 2.764-11.553 11.62 2.63.022-.09 8.406-8.405 8.406 8.405 11.552-2.54 2.764 11.553.068.292 10.945 4.652-4.495 10.473-.045.068 7.484 9.35-9.057 7.686-.203.157.45 11.89-11.709.291h-.113l-4.944 10.811-10.81-4.945-.383.158-10.743 5.079-4.967-10.72h.023l-11.89-.293.27-11.889-.427-.315L47.22 72.6l7.686-9.058v-.09l-4.63-10.945 10.946-4.652z"
      />
    </g>
    <defs>
      <clipPath id="derma-icon-4_svg__a">
        <path fill="#fff" d="M0 .725h168v168H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDermaIcon4;
