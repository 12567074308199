import React from "react";
import styled from "styled-components";
import { motion } from 'framer-motion';
import { ImageSlider } from "../../../components";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Dosing = () => {
  const { injection, dosing, dosingScheme, treatmentRegime } = useStaticQuery(
    graphql`
      query {
        injection: file(
          relativePath: { eq: "assets/images/sc-injection.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
        dosing: file(relativePath: { eq: "assets/images/45-dosing.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
        dosingScheme: file(
          relativePath: { eq: "assets/images/derma-dosing-scheme.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
        treatmentRegime: file(
          relativePath: { eq: "assets/images/derma-fewer-treatments.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
      }
    `
  );
  return (
    <>
      <Container initial={{ y: 100, opacity: 0}}
    whileInView={{ y: 0, opacity: 1}} transition={{ duration: 0.6 }}
        viewport={{ once: true }}>
        <SectionHeader>
          <TitlePosition>
            <Title>Dosing</Title>
          </TitlePosition>
          <HeaderContent>
            <Text>
              <h3>
                UZPRUVO
                <sup>
                  <small>®</small>
                </sup>{" "}
                IN DERMATOLOGY & RHEUMATOLOGY
                <br />
                <strong>
                  POSOLOGY OPTIONS
                  <sup>
                    <small>1</small>
                  </sup>
                </strong>
              </h3>
            </Text>
            <Images>
              <Image>
                <p>
                  Choose between two dose strengths to suit individual treatment
                  needs
                </p>
                <div />
              </Image>
              <Image>
                <h6>
                  Maintenance:
                  <br />
                  SC Injection*
                </h6>
                <GatsbyImage image={getImage(injection)} alt="sc injection" />
              </Image>
              <Image>
                <h6>
                  Presentation:
                  <br />
                  Pre-filled Syringe
                </h6>
                <GatsbyImage image={getImage(dosing)} alt="sc injection" />
              </Image>
            </Images>
            <p>
              <strong>SC</strong>, subcutaneous
            </p>
          </HeaderContent>
        </SectionHeader>
        <InnerContainer>
          <h4>
            <span>
              Dosing
              <sup>
                <small>1</small>
              </sup>
            </span>
          </h4>
          <ImageSlider image={dosingScheme} />
          <p>
            <strong>BW</strong>, body weight; <strong>PFS</strong>, pre-filled
            syringe; <strong>Q12W</strong>, every 12 weeks; <strong>SC</strong>,
            subcutaneous
            <br />
            <strong>1.</strong> Uzpruvo® SmPC (Feb. 2024).
          </p>
        </InnerContainer>
      </Container>
      <Container style={{ background: "#FFFFFF" }}>
        <InnerContainer>
          <h4>
            Comparatively Fewer Treatments:
            <br />
            <span>
              Dermatology & Rheumatology Treatment Regimes
              <sup>
                <small>1-3</small>
              </sup>
            </span>
            <p>
              Example of total doses in the first year of treatment (52 weeks)
              for adult patients with Crohn's disease
            </p>
          </h4>
          <ImageSlider image={treatmentRegime} />
          <p>
            <strong>Q2W</strong>, every 2 weeks; <strong>Q4W</strong>, every 4
            weeks; <strong>Q12W</strong>, every 12 weeks; <strong>SC</strong>,
            subcutaneous; <strong>W</strong>, week(s)
            <br />
            <strong>*</strong>Uzpruvo
            <sup>
              <small>®</small>
            </sup>{" "}
            weight-based dosage regime:{" "}
            <strong>BW ≤100 kg: 45 mg, BW 100 kg: 90 mg;</strong>
            <br />
            <strong>**</strong>Numbers based on the use of a 300 mg dosage.
            Secukinumab weight-based dosage frequency is Q4W for patient with{" "}
            <strong>
              BW 90 kg and Q2W with BW ≥90 kg
              <sup>
                <small>2</small>
              </sup>
            </strong>
            <br />
            <strong>1.</strong> Uzpruvo
            <sup>
              <small>®</small>
            </sup>{" "}
            SmPC (Feb. 2024); <strong>2.</strong> Cosentyx® SmPC (Aug. 2023);{" "}
            <strong>3.</strong> Taltz® SmPC (Feb. 2023).
          </p>
        </InnerContainer>
      </Container>
    </>
  );
};

const Container = styled(motion.section)`
  background: #efefef;
  color: var(--uzpruvo-purple);
  display: grid;
  position: relative;
  grid-template-columns: repeat(6, 1fr);
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const SectionHeader = styled.div`
  background: linear-gradient(90deg, #412a67 0%, #e77c6a 100%);
  color: #ffffff;
  display: grid;
  grid-column: 1/7;
  grid-template-columns: repeat(6, 1fr);
  @media screen and (min-width: 1024px) {
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Title = styled.div`
  align-items: center;
  background: var(--uzpruvo-orange);
  border-bottom-right-radius: 40px 40px;
  border-top-right-radius: 40px 40px;
  border-bottom: 1px solid #ffffff;
  border-top: 1px solid #ffffff;
  color: #ffffff;
  display: flex;
  font-size: 1.625rem;
  font-weight: 300;
  height: 100%;
  justify-content: center;
  padding: 1.5rem 3rem 1.5rem 1.25rem;
  position: absolute;
  top: -50%;
  left: 0;
  &:before {
    border-bottom: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
    content: "";
    height: calc(100% + 2px);
    background: var(--uzpruvo-orange);
    position: absolute;
    width: 500%;
    right: 100%;
    top: -1px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 2.375rem;
    padding: 0 3rem 0 0;
  }
`;

const TitlePosition = styled.div`
  grid-column: 1/7;
  position: relative;
  height: 50px;
  @media screen and (min-width: 1024px) {
    grid-column: 2/12;
    height: 80px;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  padding: 0 1.25rem;
  margin-bottom: 3rem;
  gap: 2rem;
  p {
    font-weight: 300;
    strong {
      font-weight: 500;
    }
  }
  @media screen and (min-width: 1024px) {
    grid-column: 2/12;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  h3 {
    font-weight: 300;
    font-size: 1.125rem;
    text-transform: uppercase;
    strong {
      font-weight: 500;
    }
  }
  p {
    font-size: 0.625rem;
    font-weight: 500;
  }
  @media screen and (min-width: 740px) {
    h3 {
      font-size: 1.625rem;
    }
    p {
      font-size: 1.25rem;
    }
  }
`;

const Images = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  gap: 1.5rem;
  h6 {
    font-size: 1.125rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  @media screen and (min-width: 740px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-size: 1.375rem;
    h6 {
      font-size: 1.375rem;
      text-align: center;
    }
  }
`;

const Image = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  p {
    font-weight: 500;
  }
  @media screen and (min-width: 740px) {
    &:last-of-type {
      padding: 0 2rem;
    }
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  gap: 1.25rem;
  grid-column: 1/7;
  padding: 0 1.25rem;
  margin: 3rem 0;
  h4 {
    font-size: 1.125rem;
    color: var(--uzpruvo-purple);
    font-weight: 300;
    text-transform: uppercase;
    span {
      font-weight: 500;
    }
    p {
      color: var(--uzpruvo-purple);
      text-transform: none;
      font-weight: 500;
      font-size: 1.125rem;
      padding-top: 1.5rem;
    }
  }
  p {
    font-weight: 300;
    color: #1d1d1b;
    strong {
      font-weight: 500;
    }
  }
  @media screen and (min-width: 1024px) {
    font-size: 1rem;
    gap: 2rem;
    grid-column: 2/12;
    padding: 0;
    margin: 6rem 0 4rem 0;
    h4 {
      font-size: 1.625rem;
      p {
        font-size: 1.5rem;
      }
    }
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

export default Dosing;
