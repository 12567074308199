import * as React from "react";
const SvgPopUpClose = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <rect width={40} height={40} fill="#392466" rx={10} />
    <path
      fill="#fff"
      d="M27.243 25.305 14.454 12.744a1.085 1.085 0 0 0-1.514 0 1.04 1.04 0 0 0 0 1.487l12.788 12.562c.418.41 1.097.41 1.515 0a1.04 1.04 0 0 0 0-1.488"
    />
    <path
      fill="#fff"
      d="m25.546 13.208-12.789 12.56a1.04 1.04 0 0 0 0 1.488c.418.411 1.097.411 1.515 0L27.06 14.695a1.04 1.04 0 0 0 0-1.488 1.085 1.085 0 0 0-1.515 0"
    />
  </svg>
);
export default SvgPopUpClose;
