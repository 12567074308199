import React from "react";
import styled from "styled-components";

const DrugInformation = () => {
  return (
    <Container>
      <InnerContainer>
        <FlexContainer>
          <p>
            <strong>PFS</strong> - pre-filled syringe. +29 vs 27-gauge needle of
            the reference product, Stelara
            <sup>
              <small>®1,3</small>
            </sup>
            ;{" "}
            <sup>
              <small>++</small>
            </sup>
            Plunger stopper made of bromobutyl rubber.
            <br />
            <strong>1.</strong> Uzpruvo® SmPC (Feb. 2024); <strong>2.</strong>{" "}
            Feldman SR et al. Expert Opin Biol Ther. 2023;23(3):253-60. DOI:
            10.1080/14712598.2023.2235263; <strong>3.</strong> Stelara® PI (Aug.
            2022).
          </p>
        </FlexContainer>
        <p>
          <strong>
            UZPRUVO®▼ 45 & 90 mg SOLUTION FOR INJECTION IN PRE-FILLED SYRINGE
          </strong>
          <br />
          <strong>
            ▼ This medicinal product is subject to additional monitoring.
          </strong>
          <br />
          <strong>Uzpruvo®▼ 45 mg:</strong> Each pre-filled syringe contains 45
          mg ustekinumab in 0.5 mL. <strong> Uzpruvo®▼ 90 mg:</strong> Each
          pre-filled syringe contains 90 mg ustekinumab in 1 mL. Ustekinumab is
          a fully human IgG1κ monoclonal antibody to interleukin (IL)-12/23
          produced in a murine myeloma cell line using recombinant DNA
          technology. Presentation: Pre-filled glass syringe.{" "}
          <strong>Indications:</strong> Uzpruvo is indicated for the treatment
          of plaque psoriasis, paediatric plaque psoriasis, psoriatic arthritis
          (PsA) and Crohn's disease. <strong>Dosage:</strong> Uzpruvo is
          intended for use under the guidance and supervision of physicians
          experienced in the diagnosis and treatment of conditions for which
          Uzpruvo is indicated. Refer to Summary of Product Characteristics.{" "}
          <strong>Method of administration:</strong> Subcutaneous injection.{" "}
          <strong>Contraindications:</strong> Hypersensitivity to the active
          substance or to any of the excipients listed in section 6.1.
          Clinically important, active infection.{" "}
          <strong>Warnings and precautions:</strong> In order to improve the
          traceability of biological medicinal products, the name and batch
          number of the administered product should be clearly recorded.
          Ustekinumab may have the potential to increase the risk of infections
          and reactivate latent infections. Patients should be instructed to
          seek medical advice if signs or symptoms suggestive of an infection
          occur. If a patient develops a serious infection, the patient should
          be closely monitored and Uzpruvo should not be administered until the
          infection resolves. Immunosuppressants like ustekinumab have the
          potential to increase the risk of malignancy. Serious hypersensitivity
          reactions have been reported in the postmarketing setting, in some
          cases several days after treatment. Cases of allergic alveolitis,
          eosinophilic pneumonia, and non-infectious organising pneumonia have
          been reported during post-approval use of ustekinumab. Risk factors
          for cardiovascular disease should be regularly assessed during
          treatment with ustekinumab. It is recommended that live viral or live
          bacterial vaccines (such as Bacillus of Calmette and Guérin (BCG))
          should not be given concurrently with Uzpruvo. Caution should be
          exercised when considering concomitant use of other immunosuppressants
          and Uzpruvo or when transitioning from other immunosuppressive
          biologics. It is not known whether ustekinumab may affect allergy
          immunotherapy. In patients with psoriasis, exfoliative dermatitis has
          been reported following ustekinumab treatment. Cases of lupus-related
          conditions have been reported in patients treated with Ustekinumab.
          Because there is a higher incidence of infections in the elderly
          population in general, caution should be used in treating the elderly.{" "}
          <strong>Interactions:</strong> Live vaccines should not be given
          concurrently with Uzpruvo. In psoriasis studies, the safety and
          efficacy of ustekinumab in combination with immunosuppressants,
          including biologics, or phototherapy have not been evaluated. In
          psoriatic arthritis studies, concomitant MTX use did not appear to
          influence the safety or efficacy of ustekinumab. In Crohn's disease
          and ulcerative colitis studies, concomitant use of immunosuppressants
          or corticosteroids did not appear to influence the safety or efficacy
          of Ustekinumab. <strong>Fertility, pregnancy and lactation:</strong>{" "}
          Women of childbearing potential should use effective methods of
          contraception during treatment and for at least 15 weeks after
          treatment. There are no adequate data from the use of ustekinumab in
          pregnant women. Animal studies do not indicate direct or indirect
          harmful effects with respect to pregnancy, embryonic/foetal
          development, parturition or postnatal development. As a precautionary
          measure, it is preferable to avoid the use of Uzpruvo in pregnancy.
          Limited data from published literature suggests that ustekinumab is
          excreted in human breast milk in very small amounts. Because of the
          potential for adverse reactions in nursing infants from ustekinumab, a
          decision on whether to discontinue breast-feeding during treatment and
          up to 15 weeks after treatment or to discontinue therapy with Uzpruvo
          must be made taking into account the benefit of breast-feeding to the
          child and the benefit of Uzpruvo therapy to the woman. The effect of
          ustekinumab on human fertility has not been evaluated.{" "}
          <strong>Driving and operation of machinery:</strong> Uzpruvo has no or
          negligible influence on the ability to drive and use machines.
          <strong>Undesirable effects:</strong> Upper respiratory tract
          infection, nasopharyngitis, sinusitis, dizziness, headache,
          oropharyngeal pain, diarrhoea, nausea, vomiting, pruritus, back pain,
          myalgia, arthralgia, fatigue, injection site erythema, injection site
          pain. Refer to Summary of Product Characteristics for other adverse
          effects. Adverse reactions should be reported via HPRA
          Pharmacovigilance, website: www.hpra.ie. <strong>Pack size:</strong> 1
          pre-filled syringe. A copy of the Summary of Product Characteristics
          is available upon request or go to www.clonmelhealthcare.ie.{" "}
          <strong>Marketing authorisation holder:</strong> STADA Arzneimittel
          AG, Stadastrasse 2-18, 61118 Bad Vilbel, Germany.{" "}
          <strong>Marketing authorisation number:</strong> EU/1/23/1784/001,004.
          Medicinal product subject to restricted medical prescription.{" "}
          <strong>Date last revised:</strong> February 2024.
        </p>
        <p>
          <p>
            <strong>1.</strong> Uzpruvo® SmPC (Feb. 2024)
          </p>
          <strong>Date prepared:</strong> June 2024.{" "}
          <strong>2024/ADV/UZP/155H</strong>
        </p>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 0.625rem;
  grid-column: 1/7;
  padding: 3rem 1.25rem;
  gap: 1rem;
  h6 {
    color: var(--uzpruvo-purple);
    font-size: 0.625rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  @media screen and (min-width: 1024px) {
    grid-column: 2/12;
    padding: 6rem 0;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 2rem;
  @media screen and (min-width: 1024px) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export default DrugInformation;
