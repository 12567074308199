import * as React from "react";
const SvgDermaIcon5 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 168 169"
    {...props}
  >
    <g clipPath="url(#derma-icon-5_svg__a)">
      <path
        fill="#fff"
        stroke="#7A848B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M84.011 167.871c45.927 0 83.157-37.231 83.157-83.157 0-45.927-37.23-83.158-83.157-83.158S.854 38.787.854 84.714s37.23 83.157 83.157 83.157"
      />
      <path
        stroke="#7A848B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="m37.803 118.044.652-66.953"
      />
      <path
        fill="#E77C6A"
        d="M122.151 56.688h-17.777v61.671h17.777zM92.192 74.15H74.415v44.209h17.777zM67.02 88.894H49.243v29.465H67.02z"
      />
      <path
        stroke="#7A848B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M38.455 118.359h93.27"
      />
    </g>
    <defs>
      <clipPath id="derma-icon-5_svg__a">
        <path fill="#fff" d="M0 .725h168v168H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDermaIcon5;
