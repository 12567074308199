import * as React from "react";
const SvgListArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 19"
    {...props}
  >
    <path
      fill="#E77C6A"
      d="m.223 18.893 4.742-9.394L.223.107h5.054L10.019 9.5l-4.742 9.394z"
    />
    <path
      fill="#E77C6A"
      d="M5.162.214 9.85 9.5l-4.688 9.285H.446l4.653-9.217.035-.068-.035-.069L.446.214zM5.392 0H0l4.796 9.499L0 19h5.391l4.797-9.499z"
    />
    <path
      fill="#E77C6A"
      d="m10.035 18.893 4.742-9.394L10.035.107h5.054L19.831 9.5l-4.742 9.394z"
    />
    <path
      fill="#E77C6A"
      d="M14.975.214 19.663 9.5l-4.688 9.285h-4.717l4.653-9.217.035-.068-.035-.069L10.258.214zM15.204 0H9.812l4.797 9.499-4.797 9.499h5.392L20 9.498z"
    />
  </svg>
);
export default SvgListArrow;
