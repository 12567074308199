import * as React from "react";
const SvgGastroIcon1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 176 184"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#828995"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.75}
      d="M88.012 175.769c48.113 0 87.117-38.99 87.117-87.086 0-48.097-39.004-87.087-87.117-87.087S.895 40.586.895 88.683c0 48.096 39.003 87.086 87.117 87.086"
    />
    <mask
      id="gastro-icon-1_svg__a"
      width={176}
      height={175}
      x={0}
      y={1}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        d="M88.012 175.769c48.113 0 87.117-38.99 87.117-87.086 0-48.097-39.004-87.087-87.117-87.087S.895 40.586.895 88.683c0 48.096 39.003 87.086 87.117 87.086"
      />
    </mask>
    <g mask="url(#gastro-icon-1_svg__a)">
      <path
        fill="#E77C6A"
        d="M88.577 123.423c-.589 1.389.33 2.966 1.86 3.131 2.095.188 3.885.376 5.674.4 4.026.07 8.076-.377 12.055 0 4.356.423 7.77-1.507 7.605-5.014-.306-7.343-8.076-12.051-14.527-8.614-3.72 1.977-7.393 4.213-10.619 6.896-.918.777-1.53 1.954-2.048 3.201M80.995 124.623l.777-2.188c.306-.895-.14-1.883-1.06-2.189-4.355-1.483-7.369-.73-9.653 2.471-.8 1.106-.047 2.66 1.319 2.73 2.284.094 4.568.212 6.922.306a1.73 1.73 0 0 0 1.695-1.13M111.11 128.79H63.407a3.814 3.814 0 0 0-3.814 3.813v1.765a3.814 3.814 0 0 0 3.814 3.813h47.703a3.814 3.814 0 0 0 3.814-3.813v-1.765a3.814 3.814 0 0 0-3.814-3.813"
      />
      <path
        fill="#E77C6A"
        d="M68.987 161.883V135.38a4.497 4.497 0 0 0-4.497-4.496h-.4a4.497 4.497 0 0 0-4.497 4.496v26.503a4.496 4.496 0 0 0 4.497 4.495h.4a4.496 4.496 0 0 0 4.497-4.495M114.924 161.883V135.38a4.497 4.497 0 0 0-4.497-4.496h-.401a4.497 4.497 0 0 0-4.497 4.496v26.503a4.497 4.497 0 0 0 4.497 4.495h.401a4.497 4.497 0 0 0 4.497-4.495"
      />
      <path
        fill="#E77C6A"
        d="M110.45 156.963H83.939a4.497 4.497 0 0 0-4.498 4.496v.4a4.497 4.497 0 0 0 4.498 4.496h26.511a4.496 4.496 0 0 0 4.497-4.496v-.4a4.496 4.496 0 0 0-4.497-4.496M87.565 118.998V56.154a2.495 2.495 0 0 0-2.496-2.495h-.236a2.495 2.495 0 0 0-2.496 2.496v62.843a2.495 2.495 0 0 0 2.496 2.495h.236a2.495 2.495 0 0 0 2.495-2.495"
      />
      <path
        fill="#E77C6A"
        d="M85.092 53.636H69.2a2.495 2.495 0 0 0-2.496 2.495v.235a2.495 2.495 0 0 0 2.496 2.495h15.893a2.495 2.495 0 0 0 2.496-2.495v-.235a2.495 2.495 0 0 0-2.496-2.495"
      />
      <path
        stroke="#828995"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.83}
        d="M48.126 182.76v-62.044c-.282-.047-.541-.07-.824-.117-.706 3.436-1.507 6.849-2.048 10.332H23.616c1.318-9.956 2.778-24.713 4.002-34.693.306-2.495 1.225-3.813 3.603-4.778 11.89-4.92 23.521-10.403 35.553-14.923 6.216-2.33 9.394-5.79 8.97-12.615-.282-.071-.8-.353-1.341-.377-7.347-.4-9.183-2.212-9.442-9.815-.118-3.648.636-7.579-4.544-8.026 1.86-6.284 3.225-12.24 5.415-17.865 4.756-12.31 16.764-16.405 27.736-14.64 13.939 2.236 26.253 13.887 20.037 30.104-2.425 6.308-6.098 12.216-9.865 17.841-2.637 3.93-1.436 11.557 2.99 13.393 13.374 5.531 26.771 10.944 40.074 16.664 1.625.706 3.438 2.8 3.72 4.496 1.978 11.321 3.602 24.737 5.274 36.105h-21.779c-1.083-3.601-1.813-7.343-3.296-10.992v59.926"
      />
    </g>
  </svg>
);
export default SvgGastroIcon1;
