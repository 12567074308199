import React from "react";
import { Layout } from "./src/components";
import { LocalContextProvider } from "./src/context/LocalContext";

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => (
  <LocalContextProvider>{element}</LocalContextProvider>
);