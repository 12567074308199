import * as React from "react";
const SvgLocationIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 23" {...props}>
    <path
      fill="#343434"
      fillRule="evenodd"
      d="M7.358 10.864a3.622 3.622 0 1 1 0-7.245 3.622 3.622 0 1 1 0 7.245m7.351-3.658A7.544 7.544 0 0 0 7.36 0 7.54 7.54 0 0 0 0 7.54C0 10.569 7.358 23 7.358 23s7.36-12.431 7.36-15.46q0-.168-.009-.334"
      className="location-icon_svg__fill"
    />
  </svg>
);
export default SvgLocationIcon;
