import * as React from "react";
const SvgProfileIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 49" {...props}>
    <path
      fill="#343434"
      fillRule="evenodd"
      d="M23.91.67A23.83 23.83 0 1 0 47.83 24.5 23.87 23.87 0 0 0 23.91.67M38.5 41c-1.62-.66-3.59-1.4-6-2.24-2.59-.91-2.62-1.48-2.62-3.41v-2.51c1-1 1.54-5.25 1.54-5.25.87.22 2.46-1.31 2.94-3.39s-.33-3.89-1.19-4.12c-.1 0 .3-4.51.3-6.5 0-3-.91-5-3.69-5-.74-1.21-1.95-2-5.29-2-3.5 0-5.09 2-8.39 2-1.46 0-1.76 2.36-1.76 4.92s.35 6.53.3 6.54c-.87.23-1.68 2.05-1.2 4.12s2.08 3.61 2.94 3.39c0 0 .57 4.26 1.54 5.25v2.55c0 1.93 0 2.5-2.61 3.41-2.41.84-4.37 1.58-6 2.24A22.07 22.07 0 1 1 46 24.5 22 22 0 0 1 38.5 41"
      className="profile-icon_svg__fill"
    />
  </svg>
);
export default SvgProfileIcon;
