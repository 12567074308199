import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { SectionHeader, ImageSlider } from '../../../components';

const TrialPhase = ({ text }) => {
  const { trial } = useStaticQuery(
    graphql`
      query {
        trial: file(
          relativePath: { eq: "assets/images/phase-3-trial.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
      }
    `
  );
  return (
      <Container initial={{ y: 100, opacity: 0}}
      whileInView={{ y: 0, opacity: 1}} transition={{ duration: 0.6 }}
          viewport={{ once: true }}>
      <SectionHeader
        title="Phase III Trial"
        text={text}
      />
        <InnerContainer>
          <ImageSlider image={trial} />
          <p>
            Adapted from Feldman SR et al. 20231
            <br />
            <strong>BL</strong>, baseline; <strong>BSA</strong>, body surface
            area; <strong>DLQI</strong>, Dermatology life quality index;{" "}
            <strong>EoS</strong>, end of study; <strong>PASI</strong>, Psoriasis
            Area and Severity Index; <strong>Q12W</strong>, every 12 weeks;{" "}
            <strong>SC</strong>, subcutaneous; <strong>sPGA</strong>, statistic
            physician's global assessment
            <br />
            <strong>*</strong>Stelara®; **≤100 kg body weight: 45 mg, >100 kg
            body weight: 90 mg
            <br />
            <strong>1.</strong> Feldman SR et al. Expert Opin Biol Ther.
            2023;23(3):253-60. DOI: 10.1080/14712598.2023.2235263.
          </p>
        </InnerContainer>
      </Container>
  );
};

const Container = styled(motion.section)`
  background: #EFEFEF;
  color: var(--uzpruvo-purple);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  grid-column: 1/7;
  padding: 0 1.25rem;
  margin: 3rem 0;
  h3 {
    font-size: 1rem;
  }
  h4 {
    font-size: 1rem;
    font-weight: 300;
  }
  p,
  small {
    color: #1d1d1b;
    font-weight: 300;
    strong {
        font-weight: 500;
      }
  }
  small {
    font-size: 0.625rem;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.625rem;
    gap: 2rem;
    grid-column: 2/12;
    padding: 0;
    margin: 6rem 0;
    h3 {
      font-size: 1.625rem;
    }
    h4 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
    }
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

export default TrialPhase;