import * as React from "react";
const SvgDermaIcon1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 169 169"
    {...props}
  >
    <g clipPath="url(#derma-icon-1_svg__a)">
      <path
        stroke="#828995"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M84.511 168.141c46.2 0 83.652-37.452 83.652-83.652S130.711.837 84.511.837.86 38.289.86 84.489 38.31 168.14 84.51 168.14"
      />
      <mask
        id="derma-icon-1_svg__b"
        width={159}
        height={159}
        x={5}
        y={5}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path
          fill="#fff"
          d="M84.489 163.325c43.54 0 78.836-35.296 78.836-78.836S128.029 5.652 84.489 5.652 5.652 40.95 5.652 84.49s35.297 78.836 78.837 78.836"
        />
      </mask>
      <g mask="url(#derma-icon-1_svg__b)">
        <path
          stroke="#828995"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.75}
          d="M41.193 44.788c0-5.562-3.55-5.743-5.29-5.878-1.583-.136-3.279.407-4.25 1.627-1.109 1.38-1.154 3.279-1.154 5.042 0 7.777-.022 15.578-.045 23.355-.656.678-.927 1.289-1.718.497-.566-.587-.86-1.379-1.108-2.125-.43-1.266-.678-2.6-1.38-3.753-.587-.995-1.379-1.809-2.419-2.374-1.921-1.017-4.182-.52-5.63.746-1.808 1.583-2.147 3.663-1.491 5.856.746 2.51 1.673 4.974 2.532 7.46.972 2.804 1.944 5.608 2.917 8.434.926 2.713 1.74 5.449 2.758 8.116 1.13 3.03 2.78 5.698 5.855 7.167.498.249.995.52 1.515.724.498.18.678.429.678.972v40.989M63.508 99.885c.814-.452 1.673-.859 2.374-1.492 1.65-1.537 2.871-3.391 3.866-5.403a32 32 0 0 0 2.26-5.879c.589-2.08 1.018-4.182 1.018-6.375V52.362c0-.633 0-1.266-.18-1.877-.543-1.967-1.72-3.368-3.709-4.001-.452-.136-.927-.136-1.401-.204 0 0-5.2-.769-5.155 5.155"
        />
        <path
          fill="#E77C6A"
          d="M39.882 130.882c-.226-.294-.475-.588-.656-.905-.7-1.175-.203-2.441.791-3.007.973-.565 2.374-.293 3.03.724.09.136.136.271.18.429.182.656.68.769 1.267.86 1.9.339 3.3 1.967 3.324 3.685.022 2.419-1.199 4.024-3.392 4.431-2.916.52-5.471-2.148-4.725-5.087.09-.339.158-.678.249-1.04"
        />
        <path
          stroke="#828995"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.75}
          d="M63.395 100.021a20.7 20.7 0 0 0 0 3.346c.045.497-.045 1.017-.045 1.515v33.461c0 .542-.25 19.782-.227 20.325"
        />
        <path
          fill="#E77C6A"
          d="M58.195 88.083c.813-.135 1.582-.248 2.419 0 1.628.453 2.826 2.126 2.419 4.07-.385 1.786-2.035 3.03-3.866 2.78-1.425-.18-2.465-.994-2.894-2.418-.204-.633-.543-1.018-1.153-1.289-.791-.34-1.244-.972-1.289-1.876-.045-.995.43-1.696 1.289-2.103.769-.362 1.582-.271 2.283.226.249.18.43.452.724.61"
        />
        <path
          stroke="#828995"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.75}
          d="M40.944 63.96c-.113-2.397.249-26.565.475-28.916.226-2.058.86-3.957 2.894-4.839.904-.407 1.967-.361 2.962-.248 2.826.293 4.295 2.622 4.43 5.087.069 1.537 0 27.401.114 28.939M51.819 41.238c0-2.532 1.763-4.047 4.024-4.273 1.741-.18 3.527-.248 4.838.972.927.86 1.447 2.08 1.674 3.346.587 3.21-.113 19.421-.181 22.7"
        />
        <path
          fill="#E77C6A"
          d="M53.65 127.784c-.926-.09-1.808-.294-2.6-.791-1.085-.678-1.718-1.673-2.102-2.849-.113-.361-.317-.565-.61-.723-.611-.294-1.154-.633-1.651-1.131-1.492-1.514-1.198-4.612 1.176-5.72 1.424-.655 2.826-.452 4.001.543.25.226.566.407.747.723.158.272.43.181.655.181 1.357-.045 2.532.317 3.572 1.221 1.764 1.492 2.42 4.364.701 6.602-.859 1.13-2.035 1.809-3.504 1.899-.158 0-.34 0-.294.249M30.182 85.845c0-1.424 1.312-2.803 2.962-2.69 1.221.09 2.69 1.266 2.555 2.984a2.754 2.754 0 0 1-2.804 2.532c-1.356 0-2.78-1.22-2.735-2.78M47.636 96.449c-.113 1.243-.814 2.102-1.899 2.577-1.062.475-2.148.362-3.142-.294-.023 0-.046-.045-.068-.068-.746-.678-1.425-1.379-2.465-1.74-1.04-.362-1.356-1.81-.859-2.804.52-1.063 1.673-1.56 2.781-1.13.452.158.837.135 1.289 0 2.735-.77 4.657 1.266 4.363 3.368M47.275 107.843c.09-1.175.7-2.012 1.831-2.419.543-.203.814-.474.995-1.085.52-1.718 1.537-2.555 3.12-2.736 1.696-.18 3.12.634 3.82 2.171 1.04 2.261-.587 5.019-3.096 5.087-.724 0-1.29.136-1.719.791-.633.95-1.628 1.289-2.713 1.085-1.108-.203-1.831-.972-2.17-2.034-.068-.249-.046-.52-.068-.792M38.186 108.318c.113-1.311.972-2.034 1.877-2.125 1.288-.136 2.193.452 2.486 1.56.227.882.023 1.696-.678 2.284-.655.587-1.492.678-2.283.361-.882-.361-1.402-1.04-1.402-2.034M65.837 80.329c-1.153.045-2.17-1.063-2.193-2.306 0-.927 1.13-2.148 2.193-2.126 1.47.023 2.215 1.221 2.26 2.126.068 1.447-1.13 2.35-2.193 2.306M54.26 75.943c1.244-.181 2.284 1.107 2.307 1.944.068 1.243-.656 2.351-2.125 2.464-.86.068-1.538-.361-2.013-1.13-.474-.791-.384-1.583.068-2.306.362-.566.905-.927 1.628-.972M42.052 75.965c2.374-.362 4.34 2.125 4.431 3.708.113 2.374-1.266 4.522-4.092 4.725-1.65.113-2.962-.7-3.82-2.17-.883-1.515-.747-3.007.157-4.386.701-1.063 1.741-1.786 3.098-1.877"
        />
        <path
          stroke="#828995"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.75}
          d="M131.13 24.576c-.746.972-4.702 6.217-5.697 7.483-1.899 2.374-16.436 18.381-18.901 20.235-2.826 2.125-5.788 4.07-8.817 5.9-1.741 1.04-3.934 1.832-4.838 3.573-.61 1.176-2.804 4.454-.181 9.902.814 1.674 1.921 3.098 3.504 4.093.904.565 1.967.927 2.826 1.56 1.877 1.401 3.211 3.255 4.002 5.471 1.153 3.3 3.934 5.313 7.416 5.426 8.433.045 13.339-5.969 13.972-11.395.294-2.555-3.482-8.207-.724-14.492 1.221-2.758 7.077-12.887 8.863-15.396 1.221-1.696 8.772-11.282 10.377-13.362"
        />
        <path
          stroke="#828995"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.75}
          d="M141.011 137.438c-1.583-3.934-8.863-22.789-9.903-24.756-1.311-2.487-1.221-5.11-.814-7.597.362-2.306 1.605-4.476 2.419-6.715a49 49 0 0 0 1.108-3.39c1.221-4.477-1.582-12.797-7.235-10.695-4.612 1.696-6.353 6.919-13.113 7.642-1.741.18-7.641-.746-11.304-1.492-4.364-.905-7.235 1.288-7.958 5.087-.588 3.03-.204 5.765 1.288 8.772 1.267 2.555 4.047 3.753 6.851 4.16 2.668.384 4.725 1.809 6.602 3.617 2.351 2.284 3.866 5.178 5.652 7.868 1.153 1.741 13.022 25.842 14.04 27.944"
        />
        <path
          fill="#E77C6A"
          d="M88.468 62.717c0 1.627-.294 3.278-.317 4.86-.022 2.33.294 4.522 1.266 6.647.385.837.543 1.786 1.04 2.532.905 1.357 1.922 2.623 2.962 3.89.882 1.062.995 2.531-.045 3.232-.52.34-1.38.498-1.99.34-3.504-.905-5.923-3.143-7.37-6.444-1.311-2.962-2.08-6.06-1.741-9.292.18-1.628.633-3.233 1.108-4.793.475-1.538 1.175-2.962 2.419-4.093.475-.43 1.017-.813 1.537-.474.43.271.814.836.927 1.356.159.724.204 1.47.204 2.216zM149.873 132.51c-1.899-5.133-12.141-21.886-7.959-25.978 2.103-1.967 2.329-4.522.679-6.873-1.696-2.42-4.183-2.08-6.105.475-1.469 1.967-5.019 5.607-3.775 9.021 3.346 9.043 11.281 31.019 12.095 30.115 2.352-2.555 4.115-3.686 5.585-5.63l-.543-1.153z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="derma-icon-1_svg__a">
        <path fill="#fff" d="M0 0h169v169H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDermaIcon1;
