import * as React from "react";
const SvgPsoriatic = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 164 164"
    {...props}
  >
    <g clipPath="url(#psoriatic_svg__a)">
      <path
        fill="#fff"
        stroke="#828995"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M82.01 163.188c44.834 0 81.178-36.344 81.178-81.177S126.844.834 82.011.834.833 37.178.833 82.01s36.345 81.177 81.178 81.177"
      />
      <path
        stroke="#828995"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M96.294 15.555c-.79 1.032-18.54 23.673-20.865 26.02a69 69 0 0 1-7.416 6.473c-3.005 2.26-6.143 4.322-9.368 6.253-1.843 1.119-3.269 2.523-4.212 4.366-.658 1.25-.987 2.655-1.426 4.015-.68 2.018-.461 4.015.329 5.924.746 1.82 2.04 3.29 3.708 4.344.965.592 2.084.987 2.983 1.645a12 12 0 0 1 4.235 5.792c1.228 3.489 4.168 5.639 7.876 5.749 3.006-.132 5.397-.922 6.429-1.426 1.623-.812 3.247-1.734 4.65-2.875 2.392-1.974 3.796-4.541 3.774-7.766 0-2.72-.658-5.376-.921-8.052-.22-2.413-.241-4.87.154-7.328.504-3.16 1.974-5.77 3.751-8.315 1.887-2.677 13.076-17.772 14.81-19.966 1.36-1.755 5.485-7.02 6.231-7.876M111.301 150.024c-1.689-4.168-16.082-35.762-17.179-37.846-1.382-2.633-1.294-5.419-.877-8.074.394-2.457 3.62-8.073 3.751-10.728.132-3.16-.439-6.034-3.05-8.074-1.162-.9-2.522-1.755-4.146-1.426-1.097.22-2.194.57-3.225 1.031-1.01.46-8.118 4.63-11.19 5.222-1.82.35-11.803-1.141-12.22-1.163-3.181-.154-5.55 2.501-6.538 4.673-.834 1.843-.702 3.796-.592 5.726.065 1.207.329 2.501.877 3.576 1.36 2.699 3.664 4.279 6.67 4.739 2.83.417 5.024 1.909 6.999 3.84 2.5 2.435 21.83 39.623 22.247 40.632"
      />
      <path
        fill="#E77C6A"
        d="M48.86 59.128c0 1.733-.33 3.488-.351 5.177-.022 2.48.329 4.783 1.36 7.065.395.878.57 1.909 1.097 2.699.944 1.448 2.04 2.786 3.16 4.124.943 1.12 1.053 2.699-.066 3.445-.549.373-1.47.526-2.107.351-3.73-.965-6.296-3.335-7.832-6.823-1.382-3.138-2.216-6.429-1.843-9.873.175-1.712.68-3.423 1.185-5.09.504-1.624 1.25-3.16 2.567-4.344.504-.461 1.075-.856 1.645-.505.46.285.878.9.988 1.426.175.768.219 1.558.219 2.37zM111.279 121.832a127 127 0 0 1-3.511-8.82c-.614-1.733-1.141-3.532-1.097-5.441a3.85 3.85 0 0 1 1.163-2.677c2.238-2.084 2.479-4.805.724-7.284-1.799-2.567-4.629-2.633-7.043-.241-1.053 1.031-3.773 4.761-3.269 9.719.527 5.375 9.895 23.936 11.497 27.622.724 1.646 4.629 9.193 5.353 10.839 2.808-.9 5.595-2.063 7.153-4.125 0 0-9.215-15.599-10.97-19.592"
      />
    </g>
    <defs>
      <clipPath id="psoriatic_svg__a">
        <path fill="#fff" d="M0 0h164v164H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPsoriatic;
