import * as React from "react";
const SvgPhoneIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 20" {...props}>
    <path
      fill="#343434"
      fillRule="evenodd"
      d="m17.353 15.369-.036-.027s-2.16-1.445-3.117-2.092c-.369-.249-.742-.375-1.109-.375-.508 0-.82.246-.853.275l-.04.039c-.002.002-.18.2-.578.536-.384.324-.95.265-1.395-.152-.57-.53-3.727-4.443-4.716-5.776-.198-.267-.276-.51-.237-.742.078-.477.645-.87.95-1.081l.108-.075c.614-.44.68-1.204.481-1.729-.13-.34-1.407-3.084-1.561-3.39C5.069.416 4.769 0 4.058 0c-.406 0-1.464.157-2.144.644L1.762.75C1.146 1.175 0 1.967 0 3.627c0 2.108 1.568 5.24 4.792 9.577C8.24 17.84 12.345 20 14.034 20c1.549 0 3.046-1.903 3.529-2.824.465-.889.066-1.57-.21-1.807"
      className="phone-icon_svg__fill"
    />
  </svg>
);
export default SvgPhoneIcon;
