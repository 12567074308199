import * as React from "react";
const SvgGastroIcon3 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 176 177"
    {...props}
  >
    <g clipPath="url(#gastro-icon-3_svg__a)">
      <path
        fill="#fff"
        stroke="#828995"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M88.012 175.83c48.113 0 87.117-39.004 87.117-87.117S136.125 1.596 88.012 1.596.895 40.6.895 88.713s39.003 87.117 87.117 87.117"
      />
      <path
        fill="#E77C6A"
        d="M129.522 71.054c-7.935-14.81-23.569-24.863-41.534-24.863-26.017 0-47.09 21.072-47.09 47.09 0 26.017 21.073 47.09 47.09 47.09s47.091-21.073 47.091-47.09c0-8.029-2.002-15.587-5.557-22.203zm-41.534 55.049c-18.13 0-32.845-14.692-32.845-32.846s14.692-32.845 32.845-32.845c12.526 0 23.428 7.016 28.961 17.353a32.7 32.7 0 0 1 3.885 15.492c0 18.13-14.692 32.846-32.846 32.846"
      />
      <path
        stroke="#828995"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M53.448 94.811h47.843m16.717-57.732L80.03 109.22"
      />
    </g>
    <defs>
      <clipPath id="gastro-icon-3_svg__a">
        <path fill="#fff" d="M0 .725h176v176H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGastroIcon3;
