import React, { useContext } from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import { motion } from "framer-motion";
import { LocalContext } from "../../../context";
import { ClonmelHealtcareLogo } from "../../../assets/svgs";

const DropdownNavigation = () => {
  const { dropdownOpen, toggleMenu, setShowPatientSiteDialog } =
    useContext(LocalContext);
  const dropdownOpenClose = {
    hidden: { x: "100%", transition: { duration: 0.5 } },
    visible: { x: "0", transition: { duration: 0.5 } },
  };
  return (
    <Container
      variants={dropdownOpenClose}
      initial="hidden"
      exit="hidden"
      animate={dropdownOpen ? "visible" : "hidden"}
    >
      <Options>
        <Option
          to="/gastroenterology"
          onClick={() => toggleMenu()}
          id="gastro-navigation-bar"
        >
          Gastroenterology
        </Option>
        <Option
          to="/dermatology-rheumatology"
          onClick={() => toggleMenu()}
          id="derma-navigation-bar"
        >
          Dermatology / Rheumatology
        </Option>
        <ExternalOption href="https://uzpruvopatients.com/" id="patient-navigation-bar">Go to patient website</ExternalOption>
        {/* <ExternalOption
          onClick={() => setShowPatientSiteDialog(true)}
          id="patient-navigation-bar"
        >
          Go to patient website
        </ExternalOption> */}
      </Options>
      <Links>
        <InternalLink
          to="/adverse-events"
          onClick={() => toggleMenu()}
          id="adverse-events-navigation-bar"
        >
          Adverse Events
        </InternalLink>
        <ExternalLink
          href="https://www.clonmelhealthcare.ie/contact"
          id="contact-us-navigation-bar"
        >
          Contact Us
        </ExternalLink>
      </Links>
      <EndSection>
        <div>
          <ClonmelHealtcareLogo />
        </div>
      </EndSection>
    </Container>
  );
};

const Container = styled(motion.div)`
  background: rgba(243, 246, 249, 0.95);
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  justify-content: center;
  position: absolute;
  top: calc(4rem - 1px);
  left: 0;
  width: 100%;
  height: auto;
  height: calc(100vh - 4rem);
  overflow: hidden;
  z-index: 10;
`;

const Options = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
  gap: 1.5rem;
  padding: 2rem 1.25rem;
  a {
    &:first-of-type {
      background: var(--uzpruvo-orange);
    }
    &:nth-of-type(2) {
      background: var(--uzpruvo-purple);
    }
  }
`;

const Option = styled(Link)`
  border-radius: 2.25rem;
  color: #ffffff;
  padding: 1.25rem 2.5rem;
`;

const ExternalOption = styled.a`
  background: #464a7a;
  border-radius: 2.25rem;
  color: #ffffff;
  padding: 1.25rem 2.5rem;
`;

const Links = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  justify-content: center;
  gap: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  padding: 1.25rem 1.25rem;
`;

const InternalLink = styled(Link)`
  background: var(--clonmel-healthcare-red);
  border-radius: 2.25rem;
  color: #ffffff;
  padding: 1rem 1.5rem;
`;

const ExternalLink = styled.a`
  background: var(--clonmel-healthcare-red);
  border-radius: 2.25rem;
  color: #ffffff;
  padding: 1rem 1.5rem;
`;

const EndSection = styled.div`
  background: #ffffff;
  border-top: 4px solid var(--clonmel-healthcare-red);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 0.75rem 0;
  margin-top: auto;
  div {
    grid-column: 1/7;
    display: flex;
    justify-content: flex-end;
    padding: 0 1.25rem;
    svg {
      max-width: 68px;
      height: 100%;
      width: 100%;
    }
  }
`;

export default DropdownNavigation;
