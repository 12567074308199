import { createGlobalStyle } from "styled-components";
import "../assets/fonts/fonts.css";

export const GlobalStyle = createGlobalStyle`
  :root {
    --uzpruvo-purple: #3A2666;
    --uzpruvo-orange: #E77C6A;
    --clonmel-healthcare-red: #E62644;
    --uzpruvo-dark-grey: #343434;
    --uzpruvo-light-grey: #EFEFEF;

    font-size: 16px;
    line-height: 1.25;
  } 

  * {
      margin:0;
      padding:0;
      box-sizing:border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: #F8F8F8;
    color: #000000;
    overflow-x: hidden;
    font-family: 'Noto Sans', sans-serif;
    position: relative;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;

    div.cookie-container {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    padding: 1.5em;
    background: var(--uzpruvo-purple);
    color: #FFFFFF;
    width: 100%;
    a {
      background: transparent !important;
    }
    p {
      font-size: 1rem;
      line-height: 1;
      font-weight: 300;
      a {
        text-decoration: underline;
        text-underline-position: under;
        text-decoration-thickness: 1px;
      }
    }

    button {
      margin-top: 1em;
      font-size: 1.25rem;
      font-weight: 300;
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-thickness: 1px;
      background: none;
      border: none;
      color: white;
      cursor: pointer;
      margin-right: 1rem;
    }
  }
  }

  a {
    text-decoration: none;
  }

  sup, small {
    line-height: 0;
  }

  button {
    background: transparent;
    border: none;
    color: white;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    outline: none;
    &:focus{
      outline: none;
      border: none;
    }
  }

  footer {
    font-family: 'Nunito Sans', sans-serif;
  }

  .scroll-lock {
    overflow: hidden;
  }
`;
