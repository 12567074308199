import React from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { MadeForUs, UzpruvoLogo } from "../../assets/svgs";
import { useWindowSize } from "../../hooks";

const Header = ({ location }) => {
  const { windowWidth } = useWindowSize();
  const {
    indexBackgroundImage,
    indexMiddleImage,
    indexForegroundImage,
    balloons,
  } = useStaticQuery(
    graphql`
      query {
        indexBackgroundImage: file(
          relativePath: { eq: "assets/images/homepage-background.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
        indexForegroundImage: file(
          relativePath: { eq: "assets/images/foreground-balloon.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
        indexMiddleImage: file(
          relativePath: { eq: "assets/images/middleground-balloons.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
        backgroundImage: file(
          relativePath: { eq: "assets/images/sky-background.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
        balloons: file(relativePath: { eq: "assets/images/balloons.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
      }
    `
  );
  return (
    <AnimatePresence>
      <HeaderImage>
        <Background>
          <GatsbyImage
            image={getImage(indexBackgroundImage)}
            alt="Background gradient"
            style={{ zIndex: 0, height: "100%" }}
          />
          {location.pathname !== "/" ? (
            <>
              <AnimatedOverlay
                primary
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.5, delay: 0.5 },
                }}
              >
                <GatsbyImage
                  image={getImage(balloons)}
                  alt="hot air balloons drifting"
                  style={{ zIndex: 1, height: "100%" }}
                />
              </AnimatedOverlay>
              {windowWidth >= 1024 && (
                <AnimatedOverlay
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    transition: { duration: 0.5, delay: 0.5 },
                  }}
                >
                  <GatsbyImage
                    image={getImage(balloons)}
                    alt="hot air balloons drifting"
                    style={{ zIndex: 1, height: "100%" }}
                  />
                </AnimatedOverlay>
              )}
            </>
          ) : (
            <>
              <AnimatedMiddleground>
                <GatsbyImage
                  image={getImage(indexMiddleImage)}
                  alt="hot air balloons drifting"
                  style={{ zIndex: 1, height: "100%" }}
                />
              </AnimatedMiddleground>
              <Foreground>
                <GatsbyImage
                  image={getImage(indexForegroundImage)}
                  alt="couple in hot air balloon"
                  style={{ zIndex: 1, height: "100%" }}
                />
              </Foreground>
            </>
          )}
        </Background>
        {location.pathname !== "/" ? (
          <PageTitle
            key={location.pathname}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.5, delay: 0.5 },
            }}
          >
            {location.pathname.includes("gastroenterology") ? (
              <Title>
                Uzpruvo
                <sup>
                  <small>®</small>
                </sup>{" "}
                for your
                <br />
                gastroenterology patients
              </Title>
            ) : location.pathname.includes("dermatology") ? (
              <Title>
                Uzpruvo
                <sup>
                  <small>®</small>
                </sup>{" "}
                for your
                <br />
                Dermatology / Rheumatology patients
              </Title>
            ) : (
              <Title>
                Reporting
                <br />
                of side effects
              </Title>
            )}
          </PageTitle>
        ) : (
          <Introduction
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.5, delay: 0.5 },
            }}
          >
            Introducing Uzpruvo
            <sup>
              <small>®</small>
            </sup>
            <br />
            <strong>
              The new ustekinumab
              <br />
              biosimilar
            </strong>
          </Introduction>
        )}
      </HeaderImage>
      <Container>
        <InnerContainer smaller={location.pathname !== "/"}>
          <InterchangableContent
            key={location.pathname}
            initial={{ opacity: 0, y: 20 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.5, delay: 1 },
            }}
          >
            {location.pathname === "/" ? (
              <MadeForUs />
            ) : (
              <Description>
                <Row>
                  <h3>
                    Introducing Uzpruvo
                    <sup>
                      <small>®</small>
                    </sup>
                    :
                    <br />
                    <strong>The New Ustekinumab Biosimilar</strong>
                  </h3>
                  {(windowWidth < 1024 || location.pathname !== "/") && (
                    <LogoContainer>
                      <UzpruvoLogo />
                    </LogoContainer>
                  )}
                </Row>
                <p>
                  Uzpruvo
                  <sup>
                    <small>®</small>
                  </sup>{" "}
                  is currently not approved for the ulcerative colitis
                  indication (since the originator still has exclusivity for
                  this indication)
                </p>
              </Description>
            )}
          </InterchangableContent>
          {(windowWidth >= 1024 || location.pathname === "/") && (
            <LogoContainer>
              <UzpruvoLogo />
            </LogoContainer>
          )}
        </InnerContainer>
      </Container>
    </AnimatePresence>
  );
};

const HeaderImage = styled.header`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  position: relative;
  min-height: 332px;
  @media screen and (min-width: 780px) {
    min-height: 525px;
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Container = styled.div`
  background: #d8dae0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: auto 128px; 
  svg {
    position: relative;
  }
  @media screen and (min-width: 720px) {
    grid-template-columns: auto 180px; 
  }
  @media screen and (min-width: 1024px) {
    display: flex;
  }
`;

const InnerContainer = styled.div`
  display: grid;
  grid-template-rows: ${({ smaller }) => (smaller ? "1fr" : "1fr 1fr")};
  grid-column: 1/7;
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-column: 2/12;
    grid-template-columns: ${({ smaller }) =>
      smaller ? "1fr 414px" : "1fr 474px"};
    grid-template-rows: 1fr;
    margin: 1.5rem 0;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

const Background = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  overflow: hidden;
`;

const AnimatedMiddleground = styled(motion.div)`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  animation: driftUp 30s linear infinite alternate;
  @-webkit-keyframes driftUp {
    0% {
      transform: translate(-5%, 10%);
    }
    100% {
      transform: translate(-15%, -5%);
    }
  }
`;

const Foreground = styled(motion.div)`
  height: 100%;
  left: -25%;
  position: absolute;
  top: 0;
  width: 70%;
  z-index: 1;
  overflow: hidden;
  @media screen and (min-width: 1024px) {
    left: 0;
    width: 60%;
  }
`;

const AnimatedOverlay = styled(motion.div)`
  height: auto;
  right: ${({ primary }) => (primary ? "0" : "unset")};
  left: ${({ primary }) => (primary ? "unset" : "-100%")};
  position: absolute;
  bottom: 0;
  width: 150%;
  z-index: 2;
  overflow: hidden;
  animation: drift 90s linear infinite alternate;
  @-webkit-keyframes drift {
    0% {
      -webkit-transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  @media screen and (min-width: 780px) {
    width: 100%;
    bottom: 10%;
  }
  @media screen and (min-width: 1024px) {
    bottom: unset;
    top: 25%;
    width: 90%;
  }
`;

const InterchangableContent = styled(motion.div)`
  position: relative;
  z-index: 3;
  svg {
    left: 0;
    position: absolute;
    bottom: 0.5rem;
    width: 100%;
  }
  @media screen and (min-width: 780px) {
    svg {
      bottom: 0;
    }
  }
  @media screen and (min-width: 1024px) {
    svg {
      bottom: 1.75rem;
    }
  }
`;

const PageTitle = styled(motion.div)`
  background: rgba(130, 137, 149, 0.3);
  display: grid;
  position: relative;
  z-index: 3;
  grid-column: 1/13;
  height: max-content;
  top: 6rem;
  grid-template-columns: repeat(6, 1fr);
  @media screen and (min-width: 1024px) {
    background: linear-gradient(
      to right,
      rgba(130, 137, 149, 0.3) 50%,
      rgba(130, 137, 149, 0) 50%
    );
    grid-template-columns: repeat(12, 1fr);
    top: 7rem;
  }
`;

const Introduction = styled(motion.div)`
  font-size: 1rem;
  font-weight: 300;
  grid-column: 1/7;
  position: relative;
  text-align: right;
  text-transform: uppercase;
  top: 35%;
  right: 1.25rem;
  z-index: 3;
  strong {
    font-weight: 500;
  }
  @media screen and (min-width: 780px) {
    font-size: 1.875rem;
    top: 40%;
  }
  @media screen and (min-width: 1024px) {
    grid-column: 7/12;
    top: 55%;
    right: 0;
  }
`;

const Title = styled.h2`
  color: #ffffff;
  font-size: 1.625rem;
  grid-column: 1/7;
  padding: 0 1.25rem;
  text-transform: uppercase;
  @media screen and (min-width: 780px) {
    font-size: 2.625rem;
  }
  @media screen and (min-width: 1024px) {
    grid-column: 2/7;
    font-size: 2.625rem;
    padding: 1.625rem 0;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3/7;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 0 1.25rem 1.25rem 1.25rem;
  svg {
    max-width: 182px;
    height: 100%;
    width: 100%;
  }
  @media screen and (min-width: 780px) {
    padding: 1.25rem;
  }
  @media screen and (min-width: 1024px) {
    padding: 0;
    svg {
      max-width: 100%;
    }
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.25rem;
  h3 {
    font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    strong {
      font-weight: 500;
    }
  }
  p {
    color: var(--uzpruvo-purple);
    font-size: 0.75rem;
    font-weight: 700;
    text-align: center;
  }
  @media screen and (min-width: 720px) {
    h3 {
      font-size: 1.75rem;
    }
    p {
    font-size: 1rem;
    text-align: left;
    }
  }
  @media screen and (min-width: 1024px) {
    padding: 0;
    h3 {
      font-size: 2.25rem;
    }
    p {
      font-size: 1.25rem;
      text-transform: capitalize;
    }
  }
`;

export default Header;
