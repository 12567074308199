import * as React from "react";
const SvgCircleTick = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 68 69"
    {...props}
  >
    <path
      fill="#3A2666"
      d="M29.026 67.148c15.32 0 27.747-12.419 27.747-27.748S44.346 11.657 29.026 11.657 1.274 24.076 1.274 39.4c0 15.325 12.427 27.747 27.748 27.747"
    />
    <path
      fill="#E87200"
      d="M29.026 68.422C13.02 68.422 0 55.402 0 39.4s13.02-29.022 29.026-29.022c16.005 0 29.026 13.016 29.026 29.017S45.03 68.422 29.026 68.422m0-55.49c-14.596 0-26.473 11.873-26.473 26.464 0 14.592 11.873 26.473 26.473 26.473s26.473-11.873 26.473-26.473-11.873-26.464-26.473-26.464"
    />
    <path
      fill="#3A2666"
      d="M25.025 60.018h-.083L24.099 60a3.83 3.83 0 0 1-2.369-.886C17.73 55.781 10.612 41.172 10.695 36.46a3.85 3.85 0 0 1 .585-1.972c.894-1.422 4.957-5.34 7.383-6.345a3.74 3.74 0 0 1 1.592-.287h.184a3.844 3.844 0 0 1 3.656 3.211c.367 2.225 1.261 5.625 2.317 9.207C32.678 30.106 43.19 13.948 49.744 6.299c.689-.83 2.12-2.134 6.414-3.958C58.51 1.342 61.11.461 62.778.09A3.84 3.84 0 0 1 66.83 1.76l.362.563a3.83 3.83 0 0 1-.427 4.686C54.853 19.738 41.178 37.934 28.262 58.242a3.83 3.83 0 0 1-3.233 1.776z"
    />
    <path
      fill="#fff"
      d="M53.378 8.664c-8.15 9.5-23.597 34.044-27.476 40.995-1.07-3.203-4.19-12.807-5.04-17.973h-.183c-1.515.624-5.049 3.94-5.63 4.86-.056 3.278 6.371 16.966 9.7 19.74l.847.014C38.434 36.119 52.471 17.356 64.72 4.279l-.366-.572c-2.732.607-9.535 3.203-10.979 4.957"
    />
  </svg>
);
export default SvgCircleTick;
