import React from "react";
import styled from "styled-components";

const HCPOnly = ({ onDismiss, setShowPatientSiteDialog }) => {
  return (
    <Container>
      <p>
        For Healthcare Professionals ONLY These pages are not intended for
        patients or for members of the general public.
        <br />
        <br />I confirm that I am a healthcare professional.
      </p>
      <Options>
        <button onClick={() => onDismiss()} id="healthcare-hcp-pop-up">
          Yes, I am a healthcare professional
        </button>
        <a href="https://uzpruvopatients.com/" id="patient-hcp-pop-up" >No, take me to the Patient Site</a>
        {/* <button
          onClick={() => setShowPatientSiteDialog(true)}
          id="patient-hcp-pop-up"
        >
          No, take me to the Patient Site
        </button> */}
      </Options>
      <p>
        If you select 'No', you will be redirected to the Uzpruvo Patient Site
        where you will be able to access useful patient resources and
        information.
      </p>
    </Container>
  );
};

const Container = styled.div`
  background: linear-gradient(90deg, #3a2666 0%, #e77c6a 100%);
  border: 3px solid #ffffff;
  border-radius: 30px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  padding: 2rem 0;
  gap: 2rem;
  width: 100%;
  p {
    max-width: 900px;
    padding: 0 1.25rem;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.25rem;
    p {
      padding: 0 6rem;
    }
  }
`;

const Options = styled.div`
  align-items: center;
  background: #d9d9d9;
  padding: 1rem 1.25rem;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  button {
    background: var(--uzpruvo-orange);
    &:nth-of-type(2) {
      background: var(--uzpruvo-purple);
    }
  }
  a {
    background: var(--uzpruvo-purple);
  }
  a,
  button {
    border-radius: 2.25rem;
    border: 1px solid #ffffff;
    cursor: pointer;
    color: #ffffff;
    padding: 1rem 1.5rem;
    position: relative;
    overflow: hidden;
    transition: all 1s ease-out;
    text-transform: uppercase;
    &:hover {
      transform: scale(1.025);
      &:before {
        animation: slide 2s infinite;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.8) 50%,
          rgba(128, 186, 232, 0) 99%,
          rgba(125, 185, 232, 0) 100%
        );
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        transform: translateX(100%);
        width: 100%;
        z-index: 1;

        @keyframes slide {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(100%);
          }
        }
      }
    }
    @media screen and (min-width: 1024px) {
      padding: 1.25rem 2.5rem;
    }
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

export default HCPOnly;
