import * as React from "react";
const SvgGastroIcon4 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 168 169"
    {...props}
  >
    <g clipPath="url(#gastro-icon-4_svg__a)">
      <path
        fill="#fff"
        stroke="#828995"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M84.011 167.893c45.927 0 83.157-37.23 83.157-83.157S129.938 1.58 84.011 1.58.854 38.809.854 84.736s37.23 83.157 83.157 83.157"
      />
      <path fill="#E77C6A" d="M123.005 53.698H52.209v70.796h70.796z" />
      <path
        stroke="#828995"
        strokeMiterlimit={10}
        d="M64.166 46.237v7.461M79.314 46.237v7.461M94.844 46.237v7.461M110.756 46.237l-.202 7.461"
      />
      <path
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={0.75}
        d="M66.975 74.195h7.327M83.944 74.195h7.327M100.216 74.195h7.304M100.216 74.195h7.304M66.975 86.377h7.327M83.944 86.377h7.327M100.216 86.377h7.304M67.178 98.536h7.304M84.146 98.536h7.327M67.178 110.695h7.304M84.146 110.695h7.327M100.216 98.536h7.304M100.216 110.695h7.304"
      />
      <path
        stroke="#828995"
        strokeMiterlimit={10}
        strokeWidth={0.76}
        d="M107.52 131.956H44.995V66.419"
      />
    </g>
    <defs>
      <clipPath id="gastro-icon-4_svg__a">
        <path fill="#fff" d="M0 .725h168v168H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGastroIcon4;
