import React, { useContext, useRef } from "react";
import CookieConsent from "react-cookie-consent";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import {
  Footer,
  Navigation,
  Header,
  Dialog,
  HCPOnly,
  PatientRedirect,
} from "../../components";
import { LocalContext } from "../../context";
import { GlobalStyle } from "../../styles";

const Layout = ({ location, children }) => {
  const {
    showHCPOnlyDialog,
    setShowHCPOnlyDialog,
    showPatientSiteDialog,
    setShowPatientSiteDialog,
  } = useContext(LocalContext);

  const hcpOnlyDialogRef = useRef(null);
  const patientSiteDialogRef = useRef(null);

  const closeHCPOnlyDialog = () => {
    hcpOnlyDialogRef.current.setAttribute("data-state", "close");
    hcpOnlyDialogRef.current.children[0].setAttribute("data-state", "close");
    setShowHCPOnlyDialog(false);
  };

  const closePatientSiteDialog = () => {
    patientSiteDialogRef.current.setAttribute("data-state", "close");
    patientSiteDialogRef.current.children[0].setAttribute("data-state", "close");
    setShowPatientSiteDialog(false);
  };

  return (
    <>
      <GlobalStyle />
      <Navigation />
      <Header location={location} />
      {children}
      <Footer />
      <Dialog large ref={hcpOnlyDialogRef} isVisible={showHCPOnlyDialog}>
        <HCPOnly onDismiss={closeHCPOnlyDialog} setShowPatientSiteDialog={setShowPatientSiteDialog} />
      </Dialog>
      <Dialog
        large
        ref={patientSiteDialogRef}
        isVisible={showPatientSiteDialog}
        onDismiss={closePatientSiteDialog}
        showCloseButton={true}
      >
        <PatientRedirect />
      </Dialog>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        enableDeclineButton
        onDecline={() => alert("Cookies disabled")}
        onAccept={() => initializeAndTrack(location)}
        disableStyles
        containerClasses="cookie-container"
        buttonClasses="cookie-button"
      >
        <p>
          This website intends to use cookies to improve the site and its
          experience. By continuing to browse the site, you agree to accept our
          use of cookies. If you need more information and / or do not want
          cookies placed when using the site, visit our{" "}
          <a
            href="https://www.clonmelhealthcare.ie/website-usage-and-cookie-policy"
            style={{
              color: "white",
              background: "linear-gradient(90deg, #412a67 0%, #e77c6a 100%)",
            }}
            target="blank"
          >
            cookies page
          </a>{" "}
          . To learn about the company&apos;s policies, visit the policy page.
        </p>
      </CookieConsent>
    </>
  );
};

export default Layout;
