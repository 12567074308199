import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useStaticQuery, graphql } from "gatsby";
import { SectionHeader, ImageSlider } from '../../../components';

const Safety = ({ text, pathname }) => {
  const { safety, dermaSafety } = useStaticQuery(
    graphql`
      query {
        safety: file(
          relativePath: { eq: "assets/images/gastro-safety-profile.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
        dermaSafety: file(
          relativePath: { eq: "assets/images/derma-safety-profile.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
      }
    `
  );
  return (
    <Container initial={{ y: 100, opacity: 0}}
    whileInView={{ y: 0, opacity: 1}} transition={{ duration: 0.6 }}
        viewport={{ once: true }}>
      <SectionHeader
        title="Safety"
        text={text}
      />
      <InnerContainer>
        <ImageSlider image={pathname.includes('gastroenterology') ? safety : dermaSafety}/>
        <p>
          Adapted from Feldman SR et al. 20231
          <br />
          <strong>AE</strong>, adverse event; <strong>RP</strong>, reference
          product; <strong>TEAE</strong>, treatment-emergent adverse event
          <br />
          <strong>*</strong>Stelara® <strong>1.</strong> Feldman SR et al.
          Expert Opin Biol Ther. 2023;23(3):253-60. DOI:
          10.1080/14712598.2023.2235263.
        </p>
        <small>
          <strong>Date prepared:</strong> June 2024.{" "}
          <strong>2024/ADV/UZP/155H</strong>
        </small>
      </InnerContainer>
    </Container>
  );
};

const Container = styled(motion.section)`
  background: #ffffff;
  color: var(--uzpruvo-purple);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  gap: 1.25rem;
  grid-column: 1/7;
  padding: 0 1.25rem;
  margin: 3rem 0;
  h4 {
    color: var(--uzpruvo-purple);
    font-weight: 300;
    span {
      font-weight: 500;
    }
  }
  p,
  small {
    color: #1d1d1b;
    font-weight: 300;
  }
  small {
    font-size: 0.625rem;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.625rem;
    gap: 2rem;
    grid-column: 2/12;
    padding: 0;
    margin: 6rem 0;
    p {
      font-size: 1rem;
    }
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

export default Safety;
