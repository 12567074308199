import * as React from "react";
const SvgMailIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 13" {...props}>
    <g fill="#343434" fillRule="evenodd">
      <path
        d="M0 12.306 5.996 6.31 0 .314zM10.261 8.524 18.786 0H.416l8.526 8.524a.956.956 0 0 0 1.32 0"
        className="mail-icon_svg__fill"
      />
      <path
        d="M13.441 6.876 11.227 9.09a1.72 1.72 0 0 1-1.226.508 1.72 1.72 0 0 1-1.225-.508L6.562 6.876.638 12.8h18.728L13.44 6.876zM14.007 6.31 20 12.303V.317z"
        className="mail-icon_svg__fill"
      />
    </g>
  </svg>
);
export default SvgMailIcon;
