import React from "react";
import { StaticQuery, graphql } from "gatsby";

export default ({
  pageSpecificTitle,
  pageSpecificSiteTitle,
  pageSpecificDescription,
  pageSpecificThumbnail,
  pageSpecificThumbnailAlt,
  location,
}) => {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              defaultSiteTitle
              defaultDescription
              defaultThumbnailAlt
              siteUrl
              twitterPage
              author
            }
          }
        }
      `}
      render={(data) => {
        const {
          defaultSiteTitle,
          defaultDescription,
          defaultThumbnailAlt,
          siteUrl,
          twitterPage,
          author,
        } = data.site.siteMetadata;
        const siteTitle = pageSpecificSiteTitle || defaultSiteTitle;
        const description = pageSpecificDescription || defaultDescription;
        const thumbnail = pageSpecificThumbnail || "/social-thumbnail.png";
        const thumbnailAlt = pageSpecificThumbnailAlt || defaultThumbnailAlt;
        const { pathname } = location;
        const url = `${siteUrl}${pathname}`;
        const image = `${siteUrl}${thumbnail}`;
        return (
          <>
            <title>{`${defaultSiteTitle} | ${pageSpecificTitle}`}</title>
            <meta name="image" property="og:image" content={image} />
            <meta name="description" property="og:description" content={description} />
            <meta
              name="title" property="og:title"
              content={`${defaultSiteTitle} | ${pageSpecificTitle}`}
            />
            <meta name="og:site_name" content={siteTitle} />
            <meta name="og:locale" content="en_GB" />
            <meta name="og:url" content={url} />
            <meta name="og:image:url" content={image} />
            <meta name="og:image:secure_url" content={image} />
            <meta name="og:image:alt" content={thumbnailAlt} />
            <meta name="og:image:width" content="1200" />
            <meta name="og:image:height" content="400" />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content={`${defaultSiteTitle} | ${pageSpecificTitle}`}
            />
            <meta name="twitter:url" content={url} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
          </>
        );
      }}
    />
  );
};
