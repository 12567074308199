import * as React from "react";
const SvgPsoriasis = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 166 166"
    {...props}
  >
    <g clipPath="url(#psoriasis_svg__a)">
      <path
        fill="#fff"
        stroke="#828995"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M83.011 165.178c45.38 0 82.167-36.787 82.167-82.167S128.391.844 83.011.844.844 37.63.844 83.01s36.787 82.167 82.167 82.167"
      />
      <path
        stroke="#828995"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M79.214 33.8c0-5.974-3.798-6.174-5.686-6.307-1.687-.133-3.508.422-4.574 1.754-1.177 1.466-1.244 3.531-1.244 5.419 0 8.372-.022 16.722-.044 25.094-.71.733-1 1.377-1.843.533-.622-.622-.933-1.466-1.2-2.287-.444-1.355-.733-2.798-1.488-4.042-.644-1.088-1.487-1.954-2.598-2.554-2.065-1.088-4.508-.555-6.04.8-1.955 1.71-2.31 3.953-1.621 6.307.8 2.709 1.798 5.351 2.709 8.016l3.131 9.06c1 2.91 1.888 5.841 2.954 8.728 1.221 3.265 2.998 6.13 6.307 7.706.533.267 1.066.578 1.643.778.533.2.733.466.733 1.066v50.21M103.197 93.027c.867-.489 1.799-.933 2.554-1.621 1.777-1.644 3.087-3.643 4.153-5.797a37.5 37.5 0 0 0 2.443-6.306c.622-2.243 1.11-4.509 1.11-6.84V41.972c0-.689 0-1.355-.2-2.02-.599-2.11-1.843-3.62-3.975-4.287-.466-.155-.999-.133-1.488-.2 0 0-5.596-.822-5.529 5.552"
      />
      <path
        fill="#E77C6A"
        d="M77.815 126.338c-.245-.333-.511-.644-.711-.977-.755-1.266-.222-2.621.844-3.243 1.044-.599 2.554-.311 3.242.778.089.133.156.288.2.444.2.688.71.821 1.377.932 2.02.356 3.553 2.132 3.575 3.953.045 2.599-1.288 4.331-3.642 4.753-3.131.577-5.885-2.31-5.085-5.463.089-.378.177-.733.266-1.111"
      />
      <path
        stroke="#828995"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M103.086 93.16c-.088 1.2 0 54.452 0 55.03"
      />
      <path
        fill="#E77C6A"
        d="M97.49 80.324c.866-.133 1.71-.266 2.621 0 1.732.466 3.042 2.287 2.598 4.375-.4 1.932-2.176 3.242-4.175 2.976-1.532-.2-2.643-1.088-3.11-2.599-.221-.688-.577-1.11-1.243-1.376-.866-.356-1.332-1.044-1.377-2-.044-1.065.467-1.82 1.377-2.264.822-.4 1.688-.311 2.465.244.267.2.467.489.778.644"
      />
      <path
        stroke="#828995"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M78.925 54.43c-.111-2.576.266-28.536.51-31.09.223-2.22.933-4.242 3.11-5.197.977-.422 2.11-.377 3.175-.266 3.043.31 4.62 2.82 4.753 5.463.088 1.643 0 29.447.11 31.09M90.628 30.002c0-2.71 1.91-4.33 4.308-4.597 1.866-.2 3.798-.266 5.197 1.044.999.933 1.554 2.243 1.798 3.575.622 3.443-.111 20.875-.199 24.384"
      />
      <path
        fill="#E77C6A"
        d="M92.604 123.007c-.999-.089-1.954-.311-2.798-.844-1.155-.733-1.843-1.799-2.265-3.065a1.3 1.3 0 0 0-.666-.777c-.644-.333-1.244-.689-1.777-1.222-1.62-1.643-1.288-4.952 1.266-6.129 1.532-.71 3.02-.488 4.286.578.267.244.6.422.8.777.155.289.466.2.71.2 1.444-.045 2.732.355 3.842 1.31 1.888 1.599 2.598 4.686.755 7.106-.933 1.222-2.198 1.955-3.775 2.021-.178 0-.355 0-.333.267M67.377 77.926c0-1.533 1.421-2.998 3.198-2.887 1.31.088 2.887 1.354 2.754 3.22-.111 1.554-1.444 2.731-3.02 2.731-1.466 0-2.998-1.31-2.932-2.998M86.142 89.318c-.11 1.332-.888 2.243-2.02 2.776-1.133.51-2.31.378-3.376-.31-.022 0-.045-.045-.067-.068-.8-.732-1.532-1.487-2.642-1.887-1.133-.4-1.444-1.932-.933-2.998.555-1.133 1.799-1.666 2.998-1.222.488.178.888.134 1.377 0 2.953-.821 4.996 1.377 4.686 3.642M85.743 101.577c.11-1.266.755-2.155 1.954-2.599.577-.2.888-.51 1.088-1.177.578-1.865 1.643-2.754 3.353-2.931 1.821-.2 3.354.666 4.109 2.332 1.11 2.42-.644 5.396-3.331 5.463-.778 0-1.377.155-1.844.866-.688 1.021-1.754 1.377-2.909 1.155-1.177-.223-1.976-1.044-2.331-2.199-.09-.266-.045-.555-.067-.844M75.971 102.087c.111-1.421 1.066-2.176 2.021-2.265 1.377-.133 2.376.489 2.665 1.688.244.955.022 1.821-.71 2.465-.711.622-1.622.733-2.466.377-.932-.377-1.51-1.11-1.51-2.198M105.685 71.996c-1.244.045-2.332-1.154-2.354-2.465 0-.999 1.221-2.31 2.354-2.287a2.443 2.443 0 0 1 2.42 2.287c.067 1.555-1.221 2.532-2.354 2.487M93.248 67.31c1.333-.2 2.443 1.2 2.488 2.088.066 1.333-.711 2.532-2.288 2.643-.932.066-1.665-.4-2.154-1.222-.51-.844-.422-1.687.089-2.465.4-.6.977-.999 1.754-1.043M80.124 67.333c2.554-.4 4.664 2.287 4.752 3.997.134 2.554-1.354 4.864-4.397 5.085-1.776.134-3.175-.755-4.108-2.331-.955-1.621-.822-3.243.156-4.708.754-1.133 1.887-1.91 3.33-2.021"
      />
    </g>
    <defs>
      <clipPath id="psoriasis_svg__a">
        <path fill="#fff" d="M0 0h166v166H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPsoriasis;
