import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const PatientRedirect = () => {
    const {
        background
      } = useStaticQuery(
        graphql`
          query {
            background: file(relativePath: { eq: "assets/images/pop-up-sky.png" }) {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 100)
              }
            }
          }
        `
      );
  return (
    <Container>
    <Background>
        <GatsbyImage image={getImage(background)} alt="sky" />
    </Background>
      <Content>
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, delay: 0.25 },
          }}
        >
          Uzpruvo
          <sup>
            <small>®</small>
          </sup>{" "}
          Patient Site will be ready soon.
        </motion.p>
        <motion.span
          initial={{ opacity: 0, y: 50 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, delay: 0.5 },
          }}
        >
          Watch this space.
        </motion.span>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  align-items: center;
  background: #ffffff;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 320px;
  min-height: 420px;
  padding: 1.25rem 2rem;
  position: relative;
  @media screen and (min-width: 1024px) {
    max-width: 100%;
    padding: 1.25rem 6rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-transform: uppercase;
  text-align: center;
  p {
    color: var(--uzpruvo-purple);
    font-size: 2rem;
    font-weight: 700;
  }
  span {
    color: var(--uzpruvo-orange);
    font-weight: 500;
    font-size: 1.625rem;
  }
  &:before {
    background: linear-gradient(90deg, #412a67 0%, #e77c6a 100%);
    border-radius: 0.9375rem;
    bottom: 0;
    content: "";
    height: calc(100% + 6px);
    left: -3px;
    position: absolute;
    right: 0;
    top: -3px;
    width: calc(100% + 6px);
    z-index: -2;
  }
`;

const Background = styled.div`
  border-radius: 0.75rem;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  div {
    height: 100%;
    width: 100%;
  }
`;

export default PatientRedirect;
