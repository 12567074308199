import React, { useRef } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  motion,
  useDragControls,
  useScroll,
  useTransform,
  useMotionValue,
} from "framer-motion";
import { useWindowSize } from "../../../hooks";

const ImageSlider = ({ image }) => {
  const x = useMotionValue(0);
  const constraintsRef = useRef(null);
  const dragControls = useDragControls();
  const { windowWidth } = useWindowSize();
  return (
    <Container>
      <ImageWindow>
        <ImageContainer
          ref={constraintsRef}
          onPointerDown={(event) => {
            if (windowWidth < 720) {
              dragControls.start(event, { snapToCursor: true });
            }
          }}
        >
          <Track
            drag="x"
            dragConstraints={constraintsRef}
            dragControls={dragControls}
            style={{ x }}
            mobile={windowWidth < 720}
          >
            <GatsbyImage
              image={getImage(image)}
              style={{ zIndex: 0, height: "100%" }}
            />
          </Track>
        </ImageContainer>
      </ImageWindow>
      {windowWidth < 720 && (
        <Slider onPointerDown={(event) => dragControls.start(event)}>
          <SliderIndicator
            drag="x"
            dragConstraints={constraintsRef}
            dragControls={dragControls}
            style={{ x }}
          />
        </Slider>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const ImageWindow = styled(motion.div)`
  background: #ffffff;
  border-radius: 6px;
  position: relative;
  padding: 0.5rem 1rem;
  z-index: 2;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: inherit;
    background: #ffffff;
  }
  &:before {
    content: "";
    position: absolute;
    top: -3px;
    right: 0;
    bottom: 0;
    left: -3px;
    z-index: -2;
    height: calc(100% + 6px);
    width: calc(100% + 6px);
    border-radius: 9px;
    background: linear-gradient(90deg, #412a67 0%, #e77c6a 100%);
  }
`;

const ImageContainer = styled(motion.div)`
  width: 100%;
  overflow: hidden;
  cursor: pointer;
`;

const Track = styled(motion.div)`
  width: ${({ mobile }) => (mobile ? "200%" : "100%")};
`;

const Slider = styled(motion.div)`
  background: var(--uzpruvo-orange);
  border-radius: 5px;
  height: 10px;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const SliderIndicator = styled(motion.div)`
  background: var(--uzpruvo-purple);
  border-radius: inherit;
  width: 40px;
  height: 10px;
  right: 0;
  top: 0;
  z-index: 2;
  position: absolute;
`;

export default ImageSlider;
