import React from "react";
import styled from "styled-components";
import {
  ClonmelHealtcareLogo,
  ProfileIcon,
  PhoneIcon,
  LocationIcon,
  MailIcon,
} from "../../assets/svgs";

const Footer = () => {
  return (
    <div key="footer">
      <Container>
        <InnerContainer>
          <Column>
            <ColumnTitle>Company</ColumnTitle>
            <div>
              <a href="https://www.clonmelhealthcare.ie/about-us">About Us</a>
              <a href="https://www.clonmelhealthcare.ie/products">Products</a>
              <a href="https://www.clonmelhealthcare.ie/news">News</a>
              <a href="https://www.clonmelhealthcare.ie/compliance">
                Compliance
              </a>
            </div>
          </Column>
          <Column>
            <ColumnTitle>Useful Links</ColumnTitle>
            <div>
              <a href="https://www.clonmelhealthcare.ie/privacy-notice">
                Privacy policy
              </a>
              <a href="https://www.clonmelhealthcare.ie/contact">
                Reporting of side effects
              </a>
            </div>
          </Column>
          <Column>
            <ColumnTitle>Get in touch</ColumnTitle>
            <div>
              <a href="https://www.clonmelhealthcare.ie/contact"><ProfileIcon />Contact us</a>
              <div>
                <LocationIcon />
                <p>
                  Clonmel Healthcare Ltd.
                  <br />
                  Waterford Rd
                  <br />
                  Clonmel
                  <br />
                  Co. Tipperary
                  <br />
                  E91 D768
                </p>
              </div>
              <a href="tel:052 617 7777">
                <PhoneIcon /> 052 617 7777
              </a>
              <a href="mailto:info@clonmel-health.ie">
                <MailIcon /> info@clonmel-health.ie
              </a>
              <a href="https://www.compliance-reporting-portal.stada.com/">
              <ProfileIcon /> Compliance Reporting Portal
              </a>
            </div>
          </Column>
        </InnerContainer>
      </Container>
      <EndSection>
        <div>
          <ClonmelHealtcareLogo />
        </div>
      </EndSection>
    </div>
  );
};

const Container = styled.footer`
  background: #efefef;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  padding: 0 1.25rem;
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 0.5rem 1.25rem;
  }
  @media screen and (min-width: 1024px) {
    grid-column: 2/12;
    padding: 1.25rem 0;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

const Column = styled.div`
  color: var(--uzpruvo-dark-grey);
  display: flex;
  flex-direction: column;
  svg {
    width: 18px;
  }
  div {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    gap: 0.5rem;
    margin: 1.125rem 0;
    a {
      color: var(--uzpruvo-dark-grey);
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }
    div {
      flex-direction: row;
      align-items: flex-start;
      margin: 0;
      gap: 1rem;
      svg {
        margin-top: 0.25rem;
        width: 14px;
      }
    }
  }
`;

const ColumnTitle = styled.h5`
  border-bottom: 1px solid var(--uzpruvo-dark-grey);
  font-size: 1.125rem;
  font-weight: 400;
  padding: 1rem 0;
`;

const EndSection = styled.div`
  background: #ffffff;
  border-top: 4px solid var(--clonmel-healthcare-red);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 0.75rem 0;
  div {
    grid-column: 1/7;
    display: flex;
    justify-content: flex-end;
    padding: 0 1.25rem;
    svg {
      max-width: 82px;
      height: 100%;
      width: 100%;
    }
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
    div {
      grid-column: 10/12;
      padding: 0;
    }
  }
  @media screen and (min-width: 1600px) {
    div {
      grid-column: 10/11;
    }
  }
`;

export default Footer;
