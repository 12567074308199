import React, { createContext, useState } from "react";

const LocalContext = createContext();

const LocalContextProvider = (props) => {
  const [showHCPOnlyDialog, setShowHCPOnlyDialog] = useState(true);
  const [showPatientSiteDialog, setShowPatientSiteDialog] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCookieBar, setShowCookieBar] = useState(false);
  const toggleMenu = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  return (
    <LocalContext.Provider
      value={{
        showHCPOnlyDialog,
        setShowHCPOnlyDialog,
        showPatientSiteDialog, setShowPatientSiteDialog,
        showCookieBar,
        setShowCookieBar,
        dropdownOpen,
        setDropdownOpen,
        toggleMenu,
      }}
    >
      {props.children}
    </LocalContext.Provider>
  );
};

export { LocalContextProvider };
export default LocalContext;
