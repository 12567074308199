import * as React from "react";
const SvgGastroIcon2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 174 175"
    {...props}
  >
    <g clipPath="url(#gastro-icon-2_svg__a)">
      <path
        fill="#fff"
        stroke="#7A848B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M87.012 173.84c47.566 0 86.127-38.56 86.127-86.127S134.578 1.586 87.012 1.586C39.445 1.586.885 40.146.885 87.713s38.56 86.127 86.127 86.127"
      />
      <path
        fill="#E77C6A"
        d="m81.658 69.51-25.652 36.243 22.765 15.992L104.4 85.479z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="m87.105 87.294 10.591 7.03M76.956 100.935l10.591 7.03"
      />
      <path
        stroke="#7A848B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M122.789 76.354 84.102 49.166m20.182 12.43 13.594-19.228m7.961 5.587L109.917 36.76m-5.517 48.72L81.635 69.486M60.01 124.189l-10.242 14.502m21.81-21.858-7.309 10.336-8.496-5.983 7.31-10.335m51.745-40.084-36.056 51.001-22.766-15.991 36.057-51.002"
      />
    </g>
    <defs>
      <clipPath id="gastro-icon-2_svg__a">
        <path fill="#fff" d="M0 .725h174v174H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGastroIcon2;
