import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";
import DropdownNavigation from "./DropdownNavigation";
import {
  ClonmelHealtcareLogo,
  MenuBurger,
  MenuClose,
  UzpruvoLogo,
} from "../../assets/svgs";
import { LocalContext } from "../../context";
import { useWindowSize } from "../../hooks";

const Navigation = () => {
  const { windowWidth } = useWindowSize();
  const { toggleMenu, dropdownOpen, setShowPatientSiteDialog } =
    useContext(LocalContext);
  return (
    <AnimatePresence>
      <Container>
        <CompanyLogo to="/" dropdownOpen={dropdownOpen}>
          {dropdownOpen ? (
            <motion.div
              key="open"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.5, delay: 0.5 },
              }}
            >
              <UzpruvoLogo />
            </motion.div>
          ) : (
            <motion.div
              key="closed"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.5, delay: 0.5 },
              }}
            >
              <ClonmelHealtcareLogo />
            </motion.div>
          )}
        </CompanyLogo>
        <PageLinks>
          <Link to="/gastroenterology" id="gastro-navigation-bar">
            Gastroenterology
          </Link>
          <Link to="/dermatology-rheumatology" id="derma-navigation-bar">
            Dermatology / Rheumatology
          </Link>
          <Link to="/adverse-events" id="adverse-events-navigation-bar">
            Adverse Events
          </Link>
        </PageLinks>
        <MenuToggle
          onClick={() => toggleMenu()}
          key={dropdownOpen}
          dropdownOpen={dropdownOpen}
          initial={{ opacity: 0, scale: 0 }}
          animate={{
            opacity: 1,
            scale: 1,
            transition: { duration: 0.25, delay: 0.25 },
          }}
          exit={{
            opacity: 1,
            scale: 1,
            transition: { duration: 0.25, delay: 0.25 },
          }}
        >
          {dropdownOpen ? <MenuClose /> : <MenuBurger />}
        </MenuToggle>
        <ExternalLink>
          <Button href="https://uzpruvopatients.com/" id="patient-navigation-bar">
            Go To Patient Website
          </Button>
          {/* <Button
            onClick={() => setShowPatientSiteDialog(true)}
            id="patient-navigation-bar"
          >
            Go To Patient Website
          </Button> */}
        </ExternalLink>
        {windowWidth < 1024 && <DropdownNavigation />}
      </Container>
    </AnimatePresence>
  );
};

const Container = styled(motion.nav)`
  align-items: center;
  background: rgba(243, 246, 249, 0.95);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 4rem;
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const CompanyLogo = styled(Link)`
  grid-column: 1/4;
  margin: 0.5rem 1.25rem;
  display: flex;
  div {
    width: 100%;
    height: 100%;
    svg {
      max-width: ${({ dropdownOpen }) => (dropdownOpen ? "182px" : "42px")};
    }
  }
  @media screen and (min-width: 1024px) {
    margin: 0.5rem 1.25rem;
    grid-column: 2/4;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3/4;
  }
`;

const PageLinks = styled.div`
  display: none;
  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    grid-column: 4/10;
    a {
      color: var(--uzpruvo-dark-grey);
      font-size: 1.125rem;
      font-weight: 500;
    }
  }
  @media screen and (min-width: 1600px) {
    grid-column: 5/9;
  }
`;

const ExternalLink = styled(motion.div)`
  display: none;
  position: relative;
  cursor: pointer;
  @media screen and (min-width: 1024px) {
    display: flex;
    grid-column: 11/13;
  }
`;

const Button = styled(motion.a)`
  align-items: center;
  background: var(--uzpruvo-purple);
  border: 2px solid #ffffff;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: center;
  padding: 1rem 1.5rem;
  position: absolute;
  right: -2px;
  text-transform: uppercase;
  top: 50%;
  width: calc(100% + 2rem);
  overflow: hidden;
  &:hover {
    transform: scale(1.0125);
    &:before {
      animation: slide 2s infinite;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(128, 186, 232, 0) 99%,
        rgba(125, 185, 232, 0) 100%
      );
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      transform: translateX(100%);
      width: 100%;
      z-index: 1;

      @keyframes slide {
        0% {
          transform: translateX(-100%);
        }
        100% {
          transform: translateX(100%);
        }
      }
    }
  }
`;

const MenuToggle = styled(motion.button)`
  align-items: flex-end;
  grid-column: 6/7;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1.25rem;
  svg {
    width: ${({ dropdownOpen }) => (dropdownOpen ? "18px" : "24px")};
  }
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export default Navigation;
